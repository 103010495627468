import React from "react";
import { ChargeInitializing } from "./ChargeInitializing";
import { InstantChargeActive } from "./InstantChargeActive";
import { OnTrack } from "./OnTrack";
import { NotOnTrack } from "./NotOnTrack";
import { ChargingAwayFromHome } from "./ChargingAwayFromHome";
import { useVehicleLiveData } from "../../../provider/VehicleLiveDataProvider";
import { TargetEstimatedStateEnum } from "../../../contract/generated/bff/src";

export const Charging = () => {
  const {
    vehicleId,
    atChargeLocation,
    chargeOverride,
    optimisationPending,
    activeSchedule,
  } = useVehicleLiveData();

  if (atChargeLocation) {
    if (chargeOverride) {
      return <InstantChargeActive vehicleId={vehicleId} />;
    } else if (optimisationPending) {
      return <ChargeInitializing />;
    } else if (
      activeSchedule.targetCanBeAchieved ||
      activeSchedule.targetEstimatedState === TargetEstimatedStateEnum.CAN_MEET
    ) {
      return <OnTrack activeSchedule={activeSchedule} />;
    } else {
      return <NotOnTrack activeSchedule={activeSchedule} />;
    }
  } else {
    return <ChargingAwayFromHome />;
  }
};
