// @ts-nocheck
import {
  IBatteryControlStatus,
  IWeatherResponse,
} from './';

export interface IDashboard {
    updatedAt: string;
    weather?: IWeatherResponse;
    chargingStatus: ChargingStatusEnum;
    soc: number;
    systemStatus: SystemStatusEnum;
    credit: number;
    controlledFrom?: string | null;
    controlStatus: IBatteryControlStatus;
}
export enum ChargingStatusEnum {
    CHARGING = 'charging',
    DISCHARGING = 'discharging',
    STANDBY = 'standby'
}
export enum SystemStatusEnum {
    SELF_POWERED = 'selfPowered',
    SOLAR_FIT = 'solarFit',
    BATTERY_FIT = 'batteryFit',
    GRID_TO_BATTERY = 'gridToBattery',
    GRID_TO_HOME = 'gridToHome',
    UNKNOWN = 'unknown'
}

