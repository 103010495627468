import React from "react";
import styled from "styled-components";
import { IIChargeSchedule } from "../../../contract/bff";
import { Inline, Stack, Text } from "@origin-digital/ods-core";
import { ScheduledChargeActive } from "./ScheduledChargeActive";
import { formatNullableNumberToString } from "../../../utils/formatHelpers";
import { convert24HourStringTo12HourClockTime } from "@origin-digital/node-date-time";

const orangeColor = "#ffb432";

const OrangePill = styled(Text)`
  border-radius: 4px;
  padding: 1px 6px;
  background-color: ${orangeColor};
  width: fit-content;
`;

const OldNotOnTrack = () => (
  <OrangePill tone="neutralDark" weight="medium" variant="body-small">
    Not on track
  </OrangePill>
);

interface INewNotOnTrackProps {
  estimatedBatteryLevel: number | null;
  targetChargeTime: string;
}

const NewNotOnTrack = ({
  estimatedBatteryLevel,
  targetChargeTime,
}: INewNotOnTrackProps) => (
  <Inline space="xxsmall" alignY="center" data-id="estimated-text">
    <OrangePill tone="neutralDark" weight="medium" variant="body-small">
      Estimated {formatNullableNumberToString(estimatedBatteryLevel)}%
    </OrangePill>
    <Text variant="body-small">
      by {convert24HourStringTo12HourClockTime(targetChargeTime)}
    </Text>
  </Inline>
);

interface INotOnTrackProps {
  activeSchedule: IIChargeSchedule;
}

export const NotOnTrack = ({
  activeSchedule: { targetChargeTime, estimatedBatteryLevel },
}: INotOnTrackProps) => (
  <Stack space="medium">
    {estimatedBatteryLevel === undefined ? (
      <OldNotOnTrack />
    ) : (
      <NewNotOnTrack
        estimatedBatteryLevel={estimatedBatteryLevel}
        targetChargeTime={targetChargeTime}
      />
    )}
    <ScheduledChargeActive />
  </Stack>
);
