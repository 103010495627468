import React from "react";
import { Inline, Text } from "@origin-digital/ods-core";
import { FlashIcon } from "@origin-digital/neon-common";

export const ScheduledChargeActive = () => (
  <Inline>
    <FlashIcon />
    <Text variant="body-small">Scheduled charge active</Text>
  </Inline>
);
