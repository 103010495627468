import React from "react";
import { Inline, Stack, Text } from "@origin-digital/ods-core";
import { IIChargeSchedule } from "../../../contract/bff";
import { convert24HourStringTo12HourClockTime } from "@origin-digital/node-date-time";
import { formatNullableNumberToString } from "../../../utils/formatHelpers";
import { ScheduledChargeActive } from "./ScheduledChargeActive";
import { GreenPill } from "../../Pill/Pill";

const getChargeTargetText = (
  targetBatteryLevel: number | null,
  targetChargeTime: string
) =>
  targetBatteryLevel && targetChargeTime
    ? `${formatNullableNumberToString(
        targetBatteryLevel
      )}% by ${convert24HourStringTo12HourClockTime(targetChargeTime)}`
    : "Not set or temporarily unavailable";

interface IOldOnTrackProps {
  targetBatteryLevel: number | null;
  targetChargeTime: string;
}

const OldOnTrack = ({
  targetChargeTime,
  targetBatteryLevel,
}: IOldOnTrackProps) => (
  <Stack space="xsmall">
    <GreenPill weight="medium" variant="body-small">
      On track
    </GreenPill>
    <Text variant="body-small">
      {getChargeTargetText(targetBatteryLevel, targetChargeTime)}
    </Text>
  </Stack>
);

interface INewOnTrackProps {
  estimatedBatteryLevel: number | null;
  targetChargeTime: string;
}

const NewOnTrack = ({
  targetChargeTime,
  estimatedBatteryLevel,
}: INewOnTrackProps) => (
  <Inline space="xxsmall" alignY="center" data-id="estimated-text">
    <GreenPill weight="medium" variant="body-small">
      Estimated {formatNullableNumberToString(estimatedBatteryLevel)}%
    </GreenPill>
    <Text variant="body-small">
      by {convert24HourStringTo12HourClockTime(targetChargeTime)}
    </Text>
  </Inline>
);

interface IOnTrackProps {
  activeSchedule: IIChargeSchedule;
}

export const OnTrack = ({
  activeSchedule: {
    targetBatteryLevel,
    targetChargeTime,
    estimatedBatteryLevel,
  },
}: IOnTrackProps) => (
  <Stack space="medium">
    {estimatedBatteryLevel === undefined ? (
      <OldOnTrack
        targetBatteryLevel={targetBatteryLevel}
        targetChargeTime={targetChargeTime}
      />
    ) : (
      <NewOnTrack
        estimatedBatteryLevel={estimatedBatteryLevel}
        targetChargeTime={targetChargeTime}
      />
    )}
    <ScheduledChargeActive />
  </Stack>
);
