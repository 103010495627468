import React, { useEffect } from "react";
import { Heading, Stack, Text } from "@origin-digital/ods-core";
import { formatNullableNumberToString } from "../../../utils/formatHelpers";
import {
  ChargingProgressStyle,
  cxcy,
  radius,
  RING_COLOURS,
} from "./ChargingProgressStyle";

const useInitialiseProgress = (soc: number | null) => {
  const [progress, setProgress] = React.useState(0);
  useEffect(() => setProgress(soc || 0));
  return progress;
};

export interface IChargingRingChargingProps {
  ringColour: RING_COLOURS;
  soc: number | null;
  vehicleRange: number | null;
  targetBatteryLevel: number | null;
}

export const ChargingProgress = ({
  ringColour,
  soc,
  vehicleRange,
  targetBatteryLevel,
}: IChargingRingChargingProps) => {
  const progress = useInitialiseProgress(soc);

  return (
    <ChargingProgressStyle
      soc={progress}
      targetBatteryLevel={targetBatteryLevel || 0}
      ringColour={ringColour}
    >
      <Stack id="charging-percent" space="none" alignX="center">
        <Heading data-id="soc" align="center" variant="h3">
          {`${formatNullableNumberToString(soc)}%`}
        </Heading>
        <Text
          data-id="soc"
          align="center"
          variant="body-small"
          tone="neutralDark"
        >
          {`${formatNullableNumberToString(vehicleRange)}km`}
        </Text>
      </Stack>

      <svg data-id="charging-ring" viewBox="0 0 112 112">
        <circle id="maximum-charge" cx={cxcy} cy={cxcy} r={radius} />
        <circle id="progress" cx={cxcy} cy={cxcy} r={radius} />
      </svg>
    </ChargingProgressStyle>
  );
};
