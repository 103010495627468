import React from "react";
import { formatAmount } from "@origin-digital/neon-common";
import {
  CardBackgroundVariant,
  TextToneVariants,
} from "@origin-digital/ods-types";
import { Box, Card, Text } from "@origin-digital/ods-core";

interface IDisplayOptions {
  backgroundColor: CardBackgroundVariant;
  tone: TextToneVariants;
  label: string;
  formattedAmount: string;
}

const getDisplayOptions = (amount: number): IDisplayOptions => {
  const isAmountInCredit = amount > 0;
  if (isAmountInCredit) {
    return {
      backgroundColor: "positive",
      tone: "positive",
      label: "Credit",
      formattedAmount: formatAmount({
        amount,
        prefix: "$",
        round: 2,
        suffix: " CR",
      }),
    };
  } else {
    return {
      backgroundColor: "grey50",
      tone: "neutralDark",
      label: "Cost",
      formattedAmount: formatAmount({
        amount: Math.abs(amount),
        prefix: "$",
        round: 2,
      }),
    };
  }
};

interface ICreditCostLast24HoursProps {
  amount: number;
}

export const CreditCostLast24Hours = ({
  amount,
}: ICreditCostLast24HoursProps) => {
  const { backgroundColor, tone, label, formattedAmount } =
    getDisplayOptions(amount);
  return (
    <Card
      data-id="credit-cost-last-24-hours"
      backgroundColor={backgroundColor}
      paddingYSpecific="medium"
      paddingXSpecific="medium"
    >
      <Box
        display="flex"
        alignItems="center"
        marginRight="medium"
        justifyContent="space-between"
        width="full"
      >
        <Box display="flex" alignItems="center">
          <Text variant="body-small" tone="neutralDark">
            {label} in the last 24 hours
          </Text>
        </Box>
        <Text variant="body-small" align="right" tone={tone}>
          <b>{formattedAmount}</b>
        </Text>
      </Box>
    </Card>
  );
};
