import React from "react";
import { useVehicleLiveData } from "../../provider/VehicleLiveDataProvider";
import {
  Box,
  Card,
  Column,
  Columns,
  Divider,
  Stack,
  Text,
} from "@origin-digital/ods-core";
import { Header } from "../Header/Header";
import { ChargingProgress } from "../ChargingRing/components/ChargingProgress";
import { RING_COLOURS } from "../ChargingRing/components/ChargingProgressStyle";

import { useWidgetComplete } from "../../analytics";

export function RetrievingCarDetailsCard() {
  const { vehicleId } = useVehicleLiveData();
  useWidgetComplete();

  return (
    <Card data-id={`card-ev-icharge-retrieving-${vehicleId}`}>
      <Stack space="medium">
        <Header />
        <Divider />
        <Stack space="xlarge">
          <Box marginTop="medium">
            <Columns space="large" alignY="center">
              <Column width="content">
                <ChargingProgress
                  ringColour={RING_COLOURS.DARK_GREY}
                  soc={null}
                  targetBatteryLevel={90}
                  vehicleRange={null}
                />
              </Column>
              <Column>
                <Stack data-id="charge-info-panel">
                  <Text variant="body-small">
                    Nothing to see here (just yet)
                  </Text>
                  <Text variant="body-small">
                    Come back in a few minutes to access your schedule and
                    charge settings.
                  </Text>
                </Stack>
              </Column>
            </Columns>
          </Box>
        </Stack>
      </Stack>
    </Card>
  );
}
