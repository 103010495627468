import React from "react";
import { IChargeStatus } from "../../contract/bff";
import { ChargingProgress } from "./components/ChargingProgress";
import { useVehicleLiveData } from "../../provider/VehicleLiveDataProvider";
import { RING_COLOURS } from "./components/ChargingProgressStyle";

export const ChargingRing = () => {
  const {
    chargeState: { status, soc, vehicleRange },
    activeSchedule: { targetBatteryLevel },
  } = useVehicleLiveData();

  switch (status) {
    case IChargeStatus.PLUGGED_IN_STOPPED:
    case IChargeStatus.PLUGGED_IN_INITIALIZING:
    case IChargeStatus.PLUGGED_IN_CHARGING:
    case IChargeStatus.PLUGGED_IN_COMPLETE:
      return (
        <ChargingProgress
          ringColour={RING_COLOURS.GREEN}
          soc={soc}
          targetBatteryLevel={targetBatteryLevel}
          vehicleRange={vehicleRange}
        />
      );
    default:
      return (
        <ChargingProgress
          ringColour={RING_COLOURS.DARK_GREY}
          soc={soc}
          targetBatteryLevel={targetBatteryLevel}
          vehicleRange={vehicleRange}
        />
      );
  }
};
